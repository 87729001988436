export enum COLOR_IDS {
	NEUTRAL = 'NEUTRAL',
	BRAND_PRIMARY = 'BRAND_PRIMARY',
	BRAND_ACCENT = 'BRAND_ACCENT',
	SEMANTIC_DANGER = 'SEMANTIC_DANGER',
	SEMANTIC_WARNING = 'SEMANTIC_WARNING',
	SEMANTIC_SUCCESS = 'SEMANTIC_SUCCESS',
	SEMANTIC_INFO = 'SEMANTIC_INFO',
	EXTRA_MARKETING1 = 'EXTRA_MARKETING1',
	EXTRA_MARKETING2 = 'EXTRA_MARKETING2',
	EXTRA_MARKETING3 = 'EXTRA_MARKETING3',
}

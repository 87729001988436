import { iConfig } from '@/config/config-models';

export const config: iConfig = {
	apiBaseUrl: 'https://backend.penncobalt.com/',
	providerManagementFeature: false,
	downForMaintenance: false,

	gaTrackingId: undefined,
	ga4MeasurementId: undefined,
	mixPanelId: '79b966b7a29c6963408832d4ca64fbbc',

	showDebug: false,

	localhostSubdomain: undefined,

	authRedirectUrls: [],
	storageKeys: {
		mhicRecentOrdersStorageKey: 'mhicRecentOrders',
		chunkloadRefreshKey: 'ChunkLoadError-refresh',
	},
};

export default config;
